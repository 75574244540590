import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";


const CarrierSelectForm = ({ vehicleList, address, carrier, setCarrier, goToNextStep }) => {
    const { t } = useTranslation();
    const [deliveryProviders, setDeliveryProviders] = useState([
        { id: 0, name: 'Roule Ma Poule', price: '0.00' },
        { id: 1, name: 'Chauffe Marcel', price: '0.00' },
        { id: 2, name: 'Camion Futé', price: '0.00' },
        { id: 3, name: 'Bison astucieux', price: '0.00' }
    ]);
    const [selectedProvider, setSelectedProvider] = useState(carrier);

    const calculatePrice = (addressObj, vehicleCount, providerId) => {
        const addressHash = (addressObj.startAddress + addressObj.endAddress).split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
        return ((addressHash % 100) * vehicleCount * ((providerId * addressHash % 10 / 10) + 1)).toFixed(2);
    };

    useEffect(() => {
        const vehicleCount = vehicleList.length;
        const newProviders = deliveryProviders.map(provider => ({
            ...provider,
            price: calculatePrice(address, vehicleCount, provider.id)
        }));
        setDeliveryProviders(newProviders);
    }, [vehicleList, address]);

    const handleProviderChange = (event) => {
        setSelectedProvider(Number(event.target.value));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setCarrier(deliveryProviders[selectedProvider]);
        goToNextStep();
    };

    return (
        <form
            style={{
                background: '#e0e0e0',
                padding: '20px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
            }}
            onSubmit={handleSubmit}
        >
            {deliveryProviders.map((provider) => (
                <label key={provider.id} style={{ display: 'block', margin: '5px 0' }}>
                    <input
                        type="radio"
                        name="deliveryProvider"
                        value={provider.id}
                        checked={selectedProvider === provider.id}
                        onChange={handleProviderChange}
                        style={{ marginRight: '10px' }}
                    />
                    {provider.name} - {provider.price} €
                </label>
            ))}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                }}
            >
                <button
                    className="homepage-button"
                    style={{
                        width: '120px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    type="submit"
                    disabled={!selectedProvider}
                >
                    {t('common:next')}
                </button>
            </div>
        </form>
    );
}

export default CarrierSelectForm;
