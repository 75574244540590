import 'react-toastify/dist/ReactToastify.css';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { t } from "i18next";
import React, { useState } from 'react';
import { toast } from "react-toastify";


const fakeAPI = () => {
    if (Math.random() < 0.15) {
        return { error: true };
    }

    const marks = ['Renault', 'Peugeot', 'Citroen', 'Ford', 'Toyota', 'Volkswagen'];
    const models = {
        Renault: ['Clio', 'Megane', 'Kadjar'],
        Peugeot: ['208', '308', '2008'],
        Citroen: ['C3', 'C4', 'Berlingo'],
        Ford: ['Fiesta', 'Focus', 'Kuga'],
        Toyota: ['Yaris', 'Corolla', 'RAV4'],
        Volkswagen: ['Polo', 'Golf', 'Tiguan']
    };
    const energies = ['Diesel', 'Essence', 'Hybride', 'Electrique'];
    const categories = ['Citadine', 'Berline', 'SUV', 'Coupé'];

    const mark = marks[Math.floor(Math.random() * marks.length)];
    const model = models[mark][Math.floor(Math.random() * models[mark].length)];
    const energy = energies[Math.floor(Math.random() * energies.length)];
    const category = categories[Math.floor(Math.random() * categories.length)];
    const isNonOperational = Math.random() < 0.1;
    const electricityCharge = null;

    return {
        vehicleID: Math.random().toString(36).substr(2, 9),
        mark,
        model,
        energy,
        category,
        isNonOperational,
        electricityCharge
    };
};

function VehicleInfoForm({ vehicleList, setVehicleList, goToNextStep }) {
    const [localVehicleList, setLocalVehicleList] = useState(vehicleList);
    const [registration, setRegistration] = useState('');

    const handleSearch = () => {
        const result = fakeAPI(registration);
        if (result.error) {
            toast.warn(
                "Échec de la récupération des données du véhicule.",
                {
                    position: "top-center",
                    autoClose: 3500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        } else {
            setLocalVehicleList((prevLocalVehicleList) => [...prevLocalVehicleList, result]);
        }
    };

    const handleDelete = (vehicleID) => {
        setLocalVehicleList((prevLocalVehicleList) => prevLocalVehicleList.filter(vehicle => vehicle.vehicleID !== vehicleID));
    };

    const handleNonOperationalChange = (vehicleID, isChecked) => {
        setLocalVehicleList((prevLocalVehicleList) => prevLocalVehicleList.map(vehicle =>
            vehicle.vehicleID === vehicleID ? { ...vehicle, isNonOperational: isChecked } : vehicle
        ));
    };

    const handleElectricityChargeChange = (vehicleID, charge) => {
        setLocalVehicleList((prevLocalVehicleList) => prevLocalVehicleList.map(vehicle =>
            vehicle.vehicleID === vehicleID ? { ...vehicle, electricityCharge: charge } : vehicle
        ));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setVehicleList(localVehicleList);
        goToNextStep();
    };

    return (
        <form
            onSubmit={handleSubmit}
            style={{
                background: '#e0e0e0',
                padding: '20px',
                borderRadius: '8px'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: '15px',
                    marginBottom: '15px'
                }}
            >
                <input
                    style={{
                        margin: '10px 0',
                        padding: '10px',
                        border: '1px solid #ddd'
                    }}
                    type="text"
                    placeholder={t('vehicleInfoForm:vehicleRegistration')}
                    value={registration}
                    onChange={(e) => setRegistration(e.target.value)}
                />
                <button
                    className="homepage-button"
                    style={{
                        width: '120px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        margin: '10px',
                        borderRadius: '4px',
                    }}
                    type="button"
                    onClick={handleSearch}
                    disabled={registration.length === 0}
                >
                    {t('vehicleInfoForm:add')}
                </button>
            </div>
            {localVehicleList.map((vehicle) => (
                <div
                    key={vehicle.vehicleID}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        padding: '10px',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                    }}
                >
                    <div>
                        <p>{`${t('vehicleInfoForm:brand')} : ${vehicle.mark}`}</p>
                        <p>{`${t('vehicleInfoForm:model')} : ${vehicle.model}`}</p>
                        <p>{`${t('vehicleInfoForm:energy')} : ${vehicle.energy}`}</p>
                        <p>{`${t('vehicleInfoForm:category')} : ${vehicle.category}`}</p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'end',
                            gap: '10px',
                        }}
                    >
                        <IconButton
                            onClick={() => handleDelete(vehicle.vehicleID)}
                            style={{ cursor: 'pointer', marginBottom: '-10px' }}
                            aria-label="delete"
                        >
                            <Delete />
                        </IconButton>
                        <label>
                            <input
                                type="checkbox"
                                checked={vehicle.isNonOperational}
                                onChange={(e) => handleNonOperationalChange(vehicle.vehicleID, e.target.checked)}
                            />
                            {t('vehicleInfoForm:nonRunning')}
                        </label>
                        {vehicle.energy === 'Electrique' && (
                            <div>
                                <input
                                    style={{
                                        width: '15px',
                                        padding: '10px',
                                        border: '1px solid #ddd',
                                        marginRight: '5px',
                                    }}
                                    type="text"
                                    value={vehicle.electricityCharge}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (!isNaN(value) && value >= 0 && value <= 100) {
                                            handleElectricityChargeChange(vehicle.vehicleID, value);
                                        }
                                    }}
                                />
                                {t('vehicleInfoForm:chargePercentage')}
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                }}
            >
                <button
                    className="homepage-button"
                    style={{
                        width: '120px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    type="submit"
                    disabled={localVehicleList.length === 0}
                >
                    {t('common:next')}
                </button>
            </div>
        </form>
    );
}

export default VehicleInfoForm;
