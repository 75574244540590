import InputIcon from '@mui/icons-material/Input';
import Person from '@mui/icons-material/Person';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from "react-i18next";

import LanguageSelector from "./LanguageSelector";
import { useAuth } from "../contexts/AuthContext";


const Header = () => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const logout = () => {
        const loginPageUrl = '/login';
        window.location.href = `/.auth/logout?post_logout_redirect_uri=${encodeURIComponent(window.location.origin + loginPageUrl)}`;
    };

    return (
        <AppBar
            position="static"
            style={{ backgroundColor: 'white' }}
        >
            <Toolbar
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <img
                    style={{ width: '60px' }}
                    alt='Logo'
                    src='/images/logo/logo_VPauto_195px.png'
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '30px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            color: '#57585e',
                            gap: '10px'
                        }}
                    >
                        <Person />
                        {user && (
                            <Typography style={{ fontSize: '14px' }}>
                                {user.userDetails}
                            </Typography>
                        )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            color: '#57585e',
                            padding: '15px',
                            borderRadius: '10px',
                            cursor: 'pointer',
                        }}
                        className="header-logout-button"
                        onClick={logout}
                    >
                        <InputIcon />
                        <Typography style={{ fontSize: '14px' }}>
                            {t('header:logout')}
                        </Typography>
                    </div>
                    <LanguageSelector />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
