import { Autocomplete } from "@react-google-maps/api";
import { t } from "i18next";
import React, { useState, useRef } from 'react';


const AddressForm = ({ address, setAddress, goToNextStep }) => {
    const [localAddress, setLocalAddress] = useState({
        startAddress: address.startAddress || '',
        endAddress: address.endAddress || ''
    });
    const startAddressRef = useRef();
    const endAddressRef = useRef();

    const handlePlaceSelect = (addressType, ref) => {
        if (!ref.current) return;

        const place = ref.current.getPlace();
        if (!place?.geometry) {
            console.log("Pas de détails disponibles pour l'input: '" + place?.name + "'");
            return;
        }

        setLocalAddress(prevLocalAddress => ({
            ...prevLocalAddress,
            [addressType]: place?.formatted_address
        }));
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setAddress(localAddress);
        goToNextStep();
    };

    return (
        <form
            style={{
                background: '#e0e0e0',
                padding: '20px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
            }}
            onSubmit={handleSubmit}
        >
            <div style={{ marginBottom: '10px' }}>
                <label>{t('addressForm:pickupAddress')}</label>
                <Autocomplete
                    onLoad={(ref) => startAddressRef.current = ref}
                    onPlaceChanged={() => handlePlaceSelect('startAddress', startAddressRef)}
                >
                    <input
                        type="text"
                        value={localAddress.startAddress}
                        onChange={(e) => setLocalAddress({ ...localAddress, startAddress: e.target.value })}
                        style={{ width: 'calc(100% - 20px)', padding: '10px' }}
                    />
                </Autocomplete>
            </div>
            <div style={{ marginBottom: '10px' }}>
            <label>{t('addressForm:deliveryAddress')}</label>
                <Autocomplete
                    onLoad={(ref) => endAddressRef.current = ref}
                    onPlaceChanged={() => handlePlaceSelect('endAddress', endAddressRef)}
                >
                    <input
                        type="text"
                        value={localAddress.endAddress}
                        onChange={(e) => setLocalAddress({ ...localAddress, endAddress: e.target.value })}
                        onKeyDown={handleKeyDown}
                        style={{ width: 'calc(100% - 20px)', padding: '10px' }}
                    />
                </Autocomplete>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                }}
            >
                <button
                    className="homepage-button"
                    style={{
                        width: '120px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    type="submit"
                    disabled={!localAddress.startAddress || !localAddress.endAddress}
                >
                    {t('common:next')}
                </button>
            </div>
        </form>
    );
}

export default AddressForm;
