import { LoadScript } from "@react-google-maps/api";
import React from 'react';

import ForbiddenConsts from "../utils/constants/ForbiddenConsts";


const GoogleMapProvider = ({ children }) => {
    return (
        <LoadScript
            googleMapsApiKey={ForbiddenConsts.googleMapsApiKey}
            libraries={['places']}
        >
            {children}
        </LoadScript>
    );
};

export  default GoogleMapProvider;
