import React, { useState, useEffect } from 'react';

import UserService from "../services/User/UserService";

const fetchDataFromAPI = async (apiEndpoint, setData, setLoading) => {
    setLoading(true);
    try {
        const response = await fetch(apiEndpoint);
        console.log("1: ", response);
        if (response.ok) {
            const json = await response.json();
            console.log("2: ", json);
        }
        setData(["OK"]);
    } catch (err) {
        console.error(err);
        setData(["Error"]);
    } finally {
        setLoading(false);
    }
};

const APIPage = () => {
    const [data, setData] = useState("Test API");
    const [loading, setLoading] = useState(false);


    const handleGetUser = async () => {
        try {
            const response = await UserService.getUserData();
            setData(response.data?.clientPrincipal?.userId);
        } catch (err) {
            setData("Error");
            console.error(err.message);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                color: '#344049',
                fontFamily: 'Segoe UI',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '30px',
                }}
            >
                <div
                    style={{
                        fontSize: '50px',
                        fontWeight: 'bold',
                    }}
                >
                    {loading ? 'Chargement en cours...' : data}
                </div>
                <button
                    className="homepage-button"
                    style={{
                        width: '150px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    onClick={() => fetchDataFromAPI('/api/healthCheck', setData, setLoading)}
                >
                    Health Check
                </button>
                <button
                    className="homepage-button"
                    style={{
                        width: '150px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    onClick={handleGetUser}
                >
                    Get User
                </button>
                <button
                    className="homepage-button"
                    style={{
                        width: '150px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    onClick={() => fetchDataFromAPI('/api/apimRaw', setData, setLoading)}
                >
                    APIM Raw
                </button>
                <button
                    className="homepage-button"
                    style={{
                        width: '150px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    onClick={() => fetchDataFromAPI('/api/apimRefactored', setData, setLoading)}
                >
                    APIM Refactored
                </button>
                <button
                    className="homepage-button"
                    style={{
                        width: '150px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    onClick={() => fetchDataFromAPI('/api/vehicleIdentification', setData, setLoading)}
                >
                    Vehicle Identification
                </button>
                <button
                    className="homepage-button"
                    style={{
                        width: '150px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    onClick={() => fetchDataFromAPI('/api/getToken', setData, setLoading)}
                >
                    Get Token
                </button>
                <button
                    className="homepage-button"
                    style={{
                        width: '150px',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '4px',
                    }}
                    onClick={() => fetchDataFromAPI('/api/requetePost', setData, setLoading)}
                >
                    Requête Post
                </button>
            </div>
        </div>
    );
};

export default APIPage;
