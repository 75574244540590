import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { t } from "i18next";
import React from 'react';


function SuccessAndSummary({ vehicleList, address, carrier }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                margin: '50px 20px',
                gap: '6px',
            }}
        >
            <h2
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'green',
                }}
            >
                {t('successAndSummary:success')}
                <CheckCircleIcon style={{ marginLeft: '20px' }} />
            </h2>
            <div
                style={{
                    width: '600px',
                    padding: '20px',
                    borderRadius: '8px',
                    background: '#e0e0e0',
                    margin: '5px',
                }}
            >
                <div style={{ textAlign: 'left', fontSize: '16px' }}>
                    <p><strong>{vehicleList.length === 1 ? `${t('successAndSummary:vehicleInformation')} :` : `${t('successAndSummary:vehicleInformationPlural')} :`}</strong></p>
                    <ul>
                        {vehicleList.map((vehicle, index) => (
                            <li key={index}>{`${vehicle.mark} ${vehicle.model} - ${vehicle.energy} ${vehicle.energy === "Electrique" ? vehicle.electricityCharge + t('successAndSummary:chargePercentage') : ''} - ${vehicle.isNonOperational ? t('successAndSummary:nonRunning') : t('successAndSummary:running')}`}</li>
                        ))}
                    </ul>
                    <p><strong>{`${t('successAndSummary:pickupAddress')} :`}</strong> {address.startAddress}</p>
                    <p><strong>{`${t('successAndSummary:deliveryAddress')} :`}</strong> {address.endAddress}</p>
                    <p><strong>{`${t('successAndSummary:deliveryProvider')} :`}</strong>{carrier.name}</p>
                    <p><strong>{`${t('successAndSummary:totalPricePaid')} :`}</strong> {carrier.price} €</p>
                </div>
            </div>
        </div>
    );
}

export default SuccessAndSummary;
