import React, { createContext, useContext, useEffect, useState } from 'react';

import UserService from "../services/User/UserService";

const AuthContext = createContext();
const useAuth = () => {
    return useContext(AuthContext);
};


const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        UserService.getUserData()
            .then(response => {
                if (response.data && response.data.clientPrincipal) {
                    let userDetails = response.data.clientPrincipal.userDetails;

                    if (userDetails === 'unknown') {
                        const emailClaim = response.data.clientPrincipal.claims.find(claim => claim.typ === 'emails');
                        if (emailClaim) {
                            userDetails = emailClaim.val;
                        }
                    }

                    setUser({ ...response.data.clientPrincipal, userDetails });
                }
            })
            .catch(error => {
                console.error('User verification error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <AuthContext.Provider value={{ user, isLoading }}>
            {!isLoading && children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, useAuth };
