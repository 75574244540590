import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { t } from "i18next";
import React from 'react';


const SectionWrapper = ({ title, isExpanded, isUnlocked, isCompleted, children, onEdit }) => {
    return (
        <div
            style={{
                width: '600px',
                padding: '3px',
                margin: '5px',
                borderBottom: '2px solid #ddd',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                <span
                    style={{
                        fontSize: '24px',
                        fontWeight: '600',
                    }}
                >
                    {title}
                </span>
                {isUnlocked && (
                    <div
                        style={{
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {!isExpanded &&
                            <button
                                onClick={onEdit}
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontWeight: '600',
                                }}
                            >
                                <EditIcon style={{ marginRight: '5px' }} />
                                {t('sectionWrapper:edit')}
                            </button>
                        }
                        {isCompleted ?
                            <CheckCircleIcon style={{ marginLeft: '25px', width:'25px' }} />:
                            <div style={{ width: '50px' }} />
                        }
                    </div>
                )}
            </div>
            {isExpanded && children}
        </div>
    );
};

export default SectionWrapper;
