import api from '../api';


const PaymentService = {
    postPayment: async (data) => {
        return api.post(`/api/createPaymentIntent`, data);
    },
};

export default PaymentService;

