import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import AddressForm from "../components/AddressForm";
import CarrierSelectForm from "../components/CarrierSelectForm";
import Header from "../components/Header";
import PaymentForm from "../components/PaymentForm";
import SectionWrapper from "../components/SectionWrapper";
import SuccessAndSummary from "../components/SuccessAndSummary";
import VehicleInfoForm from "../components/VehicleInfoForm";


const HomePage = () => {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState(0);
    const [vehicleList, setVehicleList] = useState([]);
    const [address, setAddress] = useState({});
    const [carrier, setCarrier] = useState(null);
    const allStepsCompleted = completedSteps >= 4;

    useEffect(() => {
        setCarrier(null);
        if (completedSteps >= 3) {
            setCompletedSteps(2);
        }
    }, [vehicleList, address]);

    const goToNextStep = () => {
        setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
        if (currentStep >= completedSteps) {
            setCompletedSteps(currentStep);
        }
    };

    return (
        <>
            <ToastContainer/>
            <Header/>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'top',
                    alignItems: 'center',
                    margin: '50px 20px',
                }}
            >
                {allStepsCompleted ?
                    <SuccessAndSummary
                        vehicleList={vehicleList}
                        address={address}
                        carrier={carrier}
                    /> :
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'top',
                            alignItems: 'center',
                            margin: '50px 20px',
                            gap: '6px',
                        }}
                    >
                        <SectionWrapper
                            title={`1. ${t('homePage:vehicleInfo')}`}
                            isExpanded={currentStep === 1}
                            isUnlocked={completedSteps >= 0}
                            isCompleted={completedSteps >= 1}
                            currentStep={currentStep}
                            onEdit={() => setCurrentStep(1)}
                        >
                            <VehicleInfoForm
                                vehicleList={vehicleList}
                                setVehicleList={setVehicleList}
                                goToNextStep={goToNextStep}
                            />
                        </SectionWrapper>
                        <SectionWrapper
                            title={`2. ${t('homePage:addresses')}`}
                            isExpanded={currentStep === 2}
                            isUnlocked={completedSteps >= 1}
                            isCompleted={completedSteps >= 2}
                            currentStep={currentStep}
                            onEdit={() => setCurrentStep(2)}
                        >
                            <AddressForm
                                address={address}
                                setAddress={setAddress}
                                goToNextStep={goToNextStep}
                            />
                        </SectionWrapper>
                        <SectionWrapper
                            title={`3. ${t('homePage:carrierSelect')}`}
                            isExpanded={currentStep === 3}
                            isUnlocked={completedSteps >= 2}
                            isCompleted={completedSteps >= 3}
                            currentStep={currentStep}
                            onEdit={() => setCurrentStep(3)}
                        >
                            <CarrierSelectForm
                                vehicleList={vehicleList}
                                address={address}
                                carrier={carrier}
                                setCarrier={setCarrier}
                                goToNextStep={goToNextStep}
                            />
                        </SectionWrapper>
                        <SectionWrapper
                            title={`4. ${t('homePage:payment')}`}
                            isExpanded={currentStep === 4}
                            isUnlocked={completedSteps >= 3}
                            isCompleted={completedSteps >= 4}
                            currentStep={currentStep}
                            onEdit={() => setCurrentStep(4)}
                        >
                            <PaymentForm
                                goToNextStep={goToNextStep}
                            />
                        </SectionWrapper>
                    </div>
                }
            </div>
        </>
    );
}

export default HomePage;
