import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { t } from "i18next";
import React from 'react';
import { toast } from "react-toastify";

import LoadingGif from '../assets/images/Fading circles.gif';
import PaymentService from "../services/Payment/PaymentService";


const PaymentForm = ({ goToNextStep }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isComplete, setIsComplete] = React.useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const amount = 100;

    const handleSuccess = () => {
        setIsLoading(false);
        setIsComplete(true);

        setTimeout(() => {
            goToNextStep();
        }, 1500);
    };

    const handleError = () => {
        toast.error(t('paymentForm:paymentFailed'), {
            position: "top-center",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        if (error) {
            handleError();
            return;
        }

        setIsLoading(true);
        try {
            const paymentIntentResponse = await PaymentService.postPayment({ amount });
            const confirmResult = await stripe.confirmCardPayment(paymentIntentResponse.data.clientSecret, {
                payment_method: paymentMethod.id,
            });

            if (confirmResult.error) {
                handleError();
            } else {
                if (confirmResult.paymentIntent.status === 'succeeded') {
                    handleSuccess();
                }
            }
        } catch (error) {
            handleError();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form
            style={{
                padding: '20px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
            }}
            onSubmit={handleSubmit}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    gap: '10px',
                }}
            >
                <div
                    style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                    }}
                >
                    {`${t('paymentForm:totalToPay')} :`}
                </div>
                <div
                    style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        color: 'blue',
                    }}
                >
                    1,00€
                </div>
            </div>
            <div style={{ height: '20px' }}>
                {isLoading ?
                    <div style={{ textAlign: 'center' }}>
                        <img src={LoadingGif} alt={t('paymentForm:loading')}/>
                    </div>:
                    isComplete ?
                    <div style={{ textAlign: 'center' }}>
                        <CheckCircleIcon style={{ color: 'green' }} />
                    </div>:
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: 'black',
                                    '::placeholder': {
                                        color: 'grey',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                }
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    height: '36px',
                    gap: '20px',
                }}
            >
                {(!isLoading && !isComplete) &&
                    <button
                        className="homepage-button"
                        style={{
                            width: '150px',
                            color: 'white',
                            border: 'none',
                            padding: '10px 20px',
                            borderRadius: '4px',
                        }}
                        type="submit"
                        disabled={!stripe}
                    >
                        {t('paymentForm:payNow')}
                    </button>
                }
            </div>
        </form>
    );
}

export default PaymentForm;
