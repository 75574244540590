import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from 'react';

import ForbiddenConsts from "../utils/constants/ForbiddenConsts";


const StripeProvider = ({ children }) => {
    const [stripe, setStripe] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function initializeStripe() {
            const stripeInstance = await loadStripe(ForbiddenConsts.stripePublicKey);
            setStripe(stripeInstance);
            setIsLoading(false);
        }

        initializeStripe();
    }, []);

    return (
        <Elements stripe={stripe}>
            {!isLoading && children}
        </Elements>
    );
};

export  default StripeProvider;
