import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/i18n';

import App from './App';
import './assets/styles/main.css';
import './i18n/i18n';
import { AuthProvider } from "./contexts/AuthContext";
import GoogleMapProvider from "./contexts/GoogleMapContext";
import StripeProvider from "./contexts/StripeContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <GoogleMapProvider>
                <StripeProvider>
                    <App/>
                </StripeProvider>
            </GoogleMapProvider>
        </AuthProvider>
    </React.StrictMode>
);
