import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from "../locales/de/translation.json";
import en from "../locales/en/translation.json";
import es from "../locales/es/translation.json";
import fr from "../locales/fr/translation.json";
import it from "../locales/it/translation.json";
import nl from "../locales/nl/translation.json";
import pl from "../locales/pl/translation.json";
import pt from "../locales/pt/translation.json";

const resources = {
    de,
    en,
    es,
    fr,
    it,
    nl,
    pl,
    pt,
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
