const LANGUAGES = [
    {
        "id": "fr",
        "name": "Français",
        "code": "fr_FR"
    },
    {
        "id": "pt",
        "name": "Portugés",
        "code": "pt_PT"
    },
    {
        "id": "de",
        "name": "Deutsch",
        "code": "de_DE"
    },
    {
        "id": "pl",
        "name": "Polski",
        "code": "pl_PL"
    },
    {
        "id": "en",
        "name": "English",
        "code": "en_EN"
    },
    {
        "id": "es",
        "name": "Español",
        "code": "es_ES"
    },
    {
        "id": "nl",
        "name": "Dutch",
        "code": "nl_NL"
    },
    {
        "id": "it",
        "name": "Italiano",
        "code": "it_IT"
    }
];

export default LANGUAGES;