import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';

import APIPage from "./pages/APIPage";
import HomePage from './pages/HomePage';
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./routing/ProtectedRoute";
import PublicRoute from "./routing/PublicRoute";


const App =() => {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="*" element={<Navigate to="/login" replace/>}/>
                    <Route path="/login" element={
                        <PublicRoute>
                            <LoginPage />
                        </PublicRoute>
                    }/>
                    <Route path="/home" element={
                        // <ProtectedRoute>
                            <HomePage />
                        // </ProtectedRoute>
                    }/>
                    <Route path="/apipage" element={<APIPage />}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
